import React from 'react';

import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import Angularjs from '../assets/angularjs.png';
import CCNA from '../assets/ccna.png';
import react from '../assets/react.png';
import AWS from '../assets/aws.png';
import GitHub from '../assets/github.png';
import Windows from '../assets/windows-10.png';
import Linux from '../assets/linux--v1.png';
import AD from '../assets/active-directory.png';
import Python from '../assets/python--v1.png';
import Nginx from '../assets/nginx.png';

const Skills = () => {
  return (
    <div name='skills' className='w-full bg-[#0a192f] text-gray-300 py-10' >
      {/* Container */}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
          <div>
              <p className='text-4xl font-bold inline border-b-4 border-[#64fcda] '>Skills</p>
              <p className='py-4'>// Technologies I'm proficient in</p>
          </div>
          <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]'>
                  <img className='w-20 mx-auto' src={AWS} alt="HTML icon" />
                  <p className='my-4'>AWS</p>
              </div>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]'>
                  <img className='w-20 mx-auto' src={GitHub} alt="HTML icon" />
                  <p className='my-4'>GITHUB</p>
              </div>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]' >
                  <img className='w-20 mx-auto' src={CCNA} alt="HTML icon" />
                  <p className='my-4'>CCNA</p>
              </div>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]'>
                  <img className='w-20 mx-auto' src={Windows} alt="HTML icon" />
                  <p className='my-4'>Windows</p>
              </div>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]'>
                  <img className='w-20 mx-auto' src={Linux} alt="HTML icon" />
                  <p className='my-4'>Linux</p>
              </div>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]'>
                  <img className='w-20 mx-auto' src={AD} alt="HTML icon" />
                  <p className='my-4'>Active Directory</p>
              </div>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]'>
                  <img className='w-20 mx-auto' src={Nginx} alt="HTML icon" />
                  <p className='my-4'>Nginx</p>
              </div>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]'>
                  <img className='w-20 mx-auto' src={Python} alt="HTML icon" />
                  <p className='my-4'>Python</p>
              </div>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]'>
                  <img className='w-20 mx-auto' src={HTML} alt="HTML icon" />
                  <p className='my-4'>HTML</p>
              </div>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]'>
                  <img className='w-20 mx-auto' src={CSS} alt="HTML icon" />
                  <p className='my-4'>CSS</p>
              </div>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]'>
                  <img className='w-20 mx-auto' src={JavaScript} alt="HTML icon" />
                  <p className='my-4'>JAVASCRIPT</p>
              </div>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]'>
                  <img className='w-20 mx-auto' src={Angularjs} alt="HTML icon" />
                  <p className='my-4'>Angular</p>
              </div>
              <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 bg-[#77809a]'>
                  <img className='w-20 mx-auto' src={react} alt="HTML icon" />
                  <p className='my-4'>react</p>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Skills;
